import React from "react"
import PropTypes from "prop-types"

// import { ResponsiveLine } from "@nivo/line"

import DashedSolidLine from "../DashedSolidLine"
import GraphToolTip from "../GraphToolTip"

const LineGraph = (props) => {
  return (
    <div style={{ height: "100%" }}>
      {/* <ResponsiveLine
        data={props.data}
        margin={{ top: 80, right: 60, bottom: 50, left: 60 }}
        xScale={{
          type: "time",
          format: "%Y-%m-%dT%H:%M:%S%Z",
          useUTC: false,
          precision: "minute",
        }}
        layers={["grid", "markers", "axes", "areas", "crosshair", "slices", "points", "mesh", "legends", DashedSolidLine]}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "#777777",
                strokeWidth: 1,
              },
            },
          },
          grid: {
            line: {
              stroke: "#777777",
              strokeWidth: 1,
            },
          },
          background: "#101010",
          textColor: "#8a94a8",
          fontSize: 12,
        }}
        pointSize={8}
        pointBorderWidth={0}
        pointBorderColor={"white"}
        useMesh={true}
        axisLeft={{
          format: (e) => Math.floor(e) === e && e,
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.yAxisLegend,
          legendOffset: -40,
          legendPosition: "middle",
        }}
        enableGridX={true}
        gridXValues={0}
        gridYValues={1}
        enableGridY={false}
        tooltip={(point) => {
          return <GraphToolTip point={point?.point} />
        }}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 0,
            translateY: -120,
            itemWidth: 150,
            itemHeight: 20,
            itemsSpacing: 8,
            symbolSize: 20,
            symbolShape: "circle",
            itemDirection: "left-to-right",
            itemTextColor: "#777",
            data: props.legends,
            effects: [],
          },
        ]}
        xFormat="time:%I:%M %p"
        axisBottom={{
          format: "%I:%M %p",
          tickValues: 3,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: props.xAxisLegend,
          legendOffset: 36,
          legendPosition: "middle",
        }}
      /> */}
    </div>
  )
}

LineGraph.propTypes = {
  data: PropTypes.array.isRequired,
  yAxisLegend: PropTypes.string,
  xAxisLegend: PropTypes.string,
}

export default LineGraph
