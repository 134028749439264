import React, { useContext } from "react"
import PropTypes from "prop-types"

import { Col, Modal, Row } from "react-bootstrap"

import Image from "../../custom/Image"
import InsightFilters from "../InsightFilters"
import ClassBurnDown from "../ClassBurnDown"
import SessionBurnDown from "../SessionBurnDown"

import { RESET_INSIGHT_DATA } from "../../../stores/insight_store/InsightActions"
import { InsightContext } from "../../../stores/insight_store/InsightStore"

import InsightModalStyle from "./style.module.scss"
import closeCircle from "../../../images/svgs/close.svg"

const InsightModal = ({ show, module_id, training_id, template_id }) => {
  const [state, dispatch] = useContext(InsightContext)

  return (
    <Modal
      show={show}
      onHide={() => {
        dispatch({ type: RESET_INSIGHT_DATA, payaload: {} })
      }}
      size="xl"
      centered
      className={``}
      contentClassName={`${InsightModalStyle.insight_modal_content} `}
      backdrop={false}
    >
      <Modal.Header className={`px-4 pt-3 ${InsightModalStyle.insight_modal_header_border}`}>
        <div className={`w-100 d-flex justify-content-between`}>
          <div className={`d-flex fs-16px align-items-center`}>
            <InsightFilters template_id={template_id} module_id={module_id} />
          </div>
          <div>
            <Image
              className={`icon`}
              src={closeCircle}
              alt={`close`}
              onClick={() => {
                dispatch({ type: RESET_INSIGHT_DATA, payaload: {} })
              }}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="12" className={`py-3 ${InsightModalStyle.insight_modal_insight_container}`} style={{ padding: "0 2%" }}>
            {state?.type === "session_burndown" && state.activeModuleData?.id && <SessionBurnDown module_id={state.activeModuleData?.id} />}
            {state?.type === "class_burndown" && <ClassBurnDown training_id={training_id} />}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

InsightModal.propTypes = {
  show: PropTypes.bool.isRequired,
  training_id: PropTypes.string.isRequired,
  template_id: PropTypes.string.isRequired,
  module_id: PropTypes.number,
}

export default InsightModal
