import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"

import Dropdown from "react-bootstrap/Dropdown"
import { getRequest } from "../../../services/rest_service"

import Image from "../../custom/Image"

import { SET_INSIGHT_DATA, SET_ACTIVE_MODULE_DATA } from "../../../stores/insight_store/InsightActions"
import { InsightContext } from "../../../stores/insight_store/InsightStore"

import down_arrow from "../../../images/svgs/down_arrow_white.svg"

const InsightFilters = ({ module_id, template_id }) => {
  const [state, dispatch] = useContext(InsightContext)

  const [allSessionsFetched, setAllSessionsFetched] = useState(false)
  const [allSessions, setAllSessions] = useState([])

  useEffect(() => {
    if (template_id) {
      fetchAllSessions()
    }
  }, [template_id])

  async function fetchAllSessions() {
    let response = await getRequest(`/default-course-templates-modules-list/${template_id}/?module_type=3&all_sessions=true`)
    if (response.success) {
      let filtred_data = response.data.results.filter((session) => session.session_type !== "0")
      setAllSessions(filtred_data)
      setAllSessionsFetched(true)
      if (!module_id && filtred_data.length > 0) {
        dispatch({ type: SET_ACTIVE_MODULE_DATA, payload: filtred_data[0] })
      }
    }
  }

  return (
    <div className={`d-flex align-items-center`}>
      <Dropdown className="breakout-groups move-dropdown">
        <Dropdown.Toggle className={`border-none shadow-none px-0 py-0`} id="classroom-insight-icon">
          <div className="d-flex align-items-center bg-303C54 px-2 py-1 pointer">
            <p className="mb-0 fs-12px bold-500 text-E0E2E5 pr-2">{state.activeChart}</p>
            <Image src={down_arrow} className={``} height={"6px"} width={"10px"} alt={`down-arrow`} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={(e) => {
              e.preventDefault()
              dispatch({ type: SET_INSIGHT_DATA, payload: { data: {}, type: "session_burndown", activeChart: "Session Burndown Chart" } })
            }}
            className="text-white fs-14px px-2 align-items-center d-flex"
          >
            <span className="ml-1">Session Burndown Chart</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => {
              e.preventDefault()
              dispatch({ type: SET_INSIGHT_DATA, payload: { data: {}, type: "class_burndown", activeChart: "Class Burndown" } })
            }}
            className="text-white fs-14px px-2 align-items-center d-flex"
          >
            <span className="ml-1">Class Burndown</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {state.activeChart === "Session Burndown Chart" && (
        <Dropdown className="breakout-groups move-dropdown ml-3">
          <Dropdown.Toggle className={`border-none shadow-none px-0 py-0`} id="classroom-insight-icon">
            <div className="d-flex align-items-center bg-303C54 px-2 py-1 pointer">
              <p className="mb-0 fs-12px bold-500 text-E0E2E5 pr-2">{state.activeModuleData?.name ? state.activeModuleData?.name : `Select Session`}</p>
              <Image src={down_arrow} className={``} height={"6px"} width={"10px"} alt={`down-arrow`} />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {allSessionsFetched && allSessions.length > 0 ? (
              allSessions.map((session, index) => {
                return (
                  <Dropdown.Item
                    key={`session_${session.id}`}
                    onClick={(e) => {
                      e.preventDefault()
                      dispatch({ type: SET_ACTIVE_MODULE_DATA, payload: { ...session } })
                    }}
                    className={`text-white fs-14px px-2 align-items-center d-flex ${state.activeModuleData?.id === session.id && `bg-FFFFFF22`}`}
                  >
                    <span className="ml-1">{session.name}</span>
                  </Dropdown.Item>
                )
              })
            ) : (
              <div className="w-100 d-flex">
                <p className="fs-12px bold-500 text-E0E2E5 pr-2 mx-auto py-2 mb-0">No sessions found!</p>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  )
}

InsightFilters.propTypes = {
  template_id: PropTypes.string.isRequired,
  module_id: PropTypes.number,
}

export default InsightFilters
