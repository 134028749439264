import React, { useEffect, useState } from "react"
import LineGraph from "../LineGraph"

import Loader from "../../custom/Loader"
import { postRequest } from "../../../services/rest_service"

const SessionBurnDown = ({ module_id }) => {
  const [sessionBurnDownData, setSessionBurnDownData] = useState([])
  const [sessionBurnDownLegends, setSessionBurnDownLegends] = useState([])
  const [insightDataFetched, setInsightDataFetched] = useState(false)

  useEffect(() => {
    if (module_id) {
      setInsightDataFetched(false)
      getSessionBurnDownData()
    }
  }, [module_id])

  const getSessionBurnDownData = async () => {
    let res = await postRequest("/insights/session_burn_down/", { module_id })
    if (res.success) {
      setSessionBurnDownData(res.data?.graphs)
      setSessionBurnDownLegends(res.data?.legends)
    } else {
      setSessionBurnDownData([])
      setSessionBurnDownLegends([])
    }
    setInsightDataFetched(true)
  }

  var graphSectionContent = null
  if (insightDataFetched) {
    if (sessionBurnDownData?.length > 0) {
      graphSectionContent = <LineGraph data={sessionBurnDownData} xAxisLegend={"Time"} yAxisLegend={"Topics"} legends={sessionBurnDownLegends} />
    } else {
      graphSectionContent = (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <p className="text-white mx-auto">Data not available</p>
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      {!insightDataFetched && (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <Loader class={`text-white mx-auto`} />
        </div>
      )}
      {graphSectionContent}
    </React.Fragment>
  )
}

export default SessionBurnDown
