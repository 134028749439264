import React from "react"
import PropTypes from "prop-types"
import InsightLineGraph from "../InsightLineGraph"
import CustomGraphToolTip from "../CustomGraphToolTip"

const ClassBurnDown = ({ training_id }) => {
  const payload = { training_id: training_id }

  const tooltip = (point) => {
    const topics_deleted = point?.point?.data?.topics_deleted?.map((topic) => <div key={`del_${topic?.id}`}>{topic?.name}</div>)
    const topics_added = point?.point?.data?.topics_added?.map((topic) => <div key={`add_${topic?.id}`}>{topic?.name}</div>)
    const additionalElements = []
    if (topics_deleted) {
      additionalElements.push(
        <div key={`topics_deleted_${point?.point?.data?.x}`}>
          <b>Topics Deleted:</b> {topics_deleted}
        </div>
      )
    }
    if (topics_added) {
      additionalElements.push(
        <div key={`topics_added_${point?.point?.data?.y}`}>
          <b>Topics Added:</b> {topics_added}
        </div>
      )
    }
    return (
      <div>
        <CustomGraphToolTip point={point?.point} xLabel="Module" yLabel="Topics" additionalElements={additionalElements} />
      </div>
    )
  }

  return (
    <InsightLineGraph
      title="Class Burndown Chart"
      fetchUrl="/insights/class_burn_down/"
      payload={payload}
      enableGridX={false}
      enableGridY={false}
      axisLeft={{ format: (e) => Math.floor(e) === e && e, legend: "Topics", legendOffset: -40, legendPosition: "middle" }}
      axisBottom={{
        format: (e) => Math.floor(e) === e && e,
        legend: "Sessions",
        legendOffset: 40,
        legendPosition: "middle",
      }}
      xScale={{ type: "linear" }}
      tooltip={tooltip}
    />
  )
}

ClassBurnDown.propTypes = {
  training_id: PropTypes.string.isRequired,
}

export default ClassBurnDown
