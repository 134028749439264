import React, { useEffect, useState } from "react"
import _ from "lodash"

import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import FormControl from "react-bootstrap/FormControl"

import CancelWhite from "./../../../images/svgs/close.svg"
import SucessTick from "./../../../images/svgs/sucesstick.svg"
import CSV from "./../../../images/svgs/csv.svg"
import Error from "./../../../images/svgs/error.svg"
import Document from "./../../../images/svgs/document.svg"
import Delete from "./../../../images/svgs/delete.svg"

import Btn from "./../Button"
import Notification from "./../Notification"
import pixelResize from "./../../utilities/pixelresize"
import DeleteModal from "./../DeleteModal"

import StylesM from "./../../upload-resource.module.scss"
import Styles from "./style.module.scss"

import { postRequest } from "../../../services/rest_service"
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input"
import Image from "../Image"
import { HasCreatePermission, HasDeletePermission, HasEditPermission } from "../../../utils/PermissionDataAccess"
import { STUDENT } from "../../../constants/permissionsConstant"
import NoPermissionError from "../../no-permission-error"

const SAMPLE_FILE = "https://turient-static-bucket.s3.amazonaws.com/sample_files/turient_new_add_students_sample.csv"

function UploadParticipantsModal(props) {
  const [showAddStudentsModal, setShowAddStudentsModal] = useState(false)
  const [showUploadStudentsModal, setShowUploadStudentsModal] = useState(false)
  const [showTotalStudentsAddedModal, setShowTotalStudentsAddedModal] = useState(false)

  const [studentsData, setStudentsData] = useState([{ name: "", email: "", status: false, valid: true, phone_number_with_cc: undefined }])
  const [toastData, setToastData] = useState(null)
  const [studentsAddedCount, setStudentsAddedCount] = useState(0)
  const [clickedSubmit, setClickedSubmit] = useState(false)
  const [isValidFile, setIsValidFile] = useState(true)
  const [fileName, setFileName] = useState(null)
  const [bulkUploadData, setBulkUploadData] = useState(null)
  const [fileStatus, setFileStatus] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [deleteModalData, setDeleteModalData] = useState(null)

  const addStudent = () => {
    let dummy = [...studentsData]
    let student = { name: "", email: "", status: false, valid: true, phone_number_with_cc: undefined }
    dummy.push(student)
    setStudentsData(dummy)
  }

  useEffect(() => {
    let ele = document.getElementById("scrollable_div")
    if (ele) {
      ele.scrollTo({ top: ele.scrollHeight, behavior: "smooth" })
    }
  }, [studentsData.length])

  useEffect(() => {
    if (props.show) {
      setShowAddStudentsModal(true)
      if (studentsData.length < 1) {
        addStudent()
      }
    }
  }, [props.show])

  const resetStates = () => {
    let arr = [{ name: "", email: "", status: false, valid: true, phone_number_with_cc: undefined }]
    setStudentsData(arr)
    setClickedSubmit(false)
    setStudentsAddedCount(0)
    setShowAddStudentsModal(false)
    setShowTotalStudentsAddedModal(false)
    props.setShow(false)
  }

  function deleteParticipant(index) {
    let updatedParticipantsList = [...studentsData]
    updatedParticipantsList.splice(index, 1)
    setStudentsData(updatedParticipantsList)
    // setToastData({ type: "success", title: "Participant deleted successfully" })
  }

  const handleBulkUpload = async (file) => {
    try {
      setFileStatus("uploading")
      let formData = new FormData()
      formData.append("file", file[0])
      setFileName(file[0].name)
      let response = await postRequest(`/trainings/${props.training_id}/students/validate_bulk_upload/`, formData)
      if (response.success) {
        setFileStatus("uploaded")
        setBulkUploadData(response.data)
      } else {
        setFileStatus("error")
        setErrorMessage(response.data)
        setToastData({ type: "error", title: response.data })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const isEmailValid = (email) => {
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$/,
      r = true
    if (email === "") {
      r = false
    } else if (email !== "" && email?.match(mailformat) === null) {
      r = false
    }
    return r
  }

  const allEmailValidation = () => {
    let invalidData = []
    let addedStudent = []
    let dummy = [...studentsData]
    for (let i = 0; i < studentsData.length; i++) {
      if (studentsData[i].status == true) {
        addedStudent.push(studentsData[i])
      }
      if (studentsData[i].name !== "" && studentsData[i].email !== "") {
        let phoneNumberValid =
          studentsData[i].phone_number_with_cc === undefined ||
          studentsData[i].phone_number_with_cc === "" ||
          (studentsData[i].phone_number_with_cc && isValidPhoneNumber(studentsData[i].phone_number_with_cc))
        if (!isEmailValid(studentsData[i].email) || !phoneNumberValid) {
          dummy[i].valid = false
          invalidData.push(dummy)
        } else {
          dummy[i].valid = true
        }
      } else {
        dummy[i].valid = false
        invalidData.push(dummy)
      }
      setStudentsData(dummy)
    }
    if (invalidData.length < 1) {
      setClickedSubmit(true)
      handleAddStudents()
      setBulkUploadData(null)
      setFileStatus("")
      if (addedStudent.length === studentsData.length) {
        resetStates()
      }
    }
  }

  const validateAndAdd = (event) => {
    try {
      event.preventDefault()
      event.stopPropagation()
      let new_file = event.target.files
      if (new_file[0].name.indexOf(".csv") > -1) {
        handleBulkUpload(new_file)
      } else {
        setIsValidFile(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleAddStudents = async () => {
    const formData = new FormData()
    let allStudentsList = studentsData.filter((data) => {
      if (data.phone_number_with_cc) {
        let { countryCallingCode, nationalNumber } = parsePhoneNumber(data.phone_number_with_cc)
        data["country_code"] = nationalNumber ? `+${countryCallingCode}` : ""
        data["phone_number"] = nationalNumber
      }
      if (!data.status) {
        return true
      }
    })

    formData.append("students", JSON.stringify(allStudentsList))

    let response = await postRequest(`/trainings/${props.training_id}/students/upload/`, formData)
    if (response.success) {
      setStudentsData((prev) => {
        return prev.map((data) => {
          data.status = true
          return data
        })
      })

      setStudentsAddedCount((prev) => prev + allStudentsList.length)
      props.refetchParticipants()
    } else {
      setToastData({ type: "error", title: response.msg })
    }
  }

  useEffect(() => {
    if (clickedSubmit) {
      let arr = studentsData.filter((res) => res.status === true)
      let empty_arr = studentsData.filter((res) => res.name === "" && res.email === "")
      if (arr.length + empty_arr.length === studentsData.length) {
        setShowTotalStudentsAddedModal(true)
        setShowAddStudentsModal(false)
        setClickedSubmit(false)
        props.setShow(false)
      }
    }
  }, [studentsData, clickedSubmit])

  const handleShowCount = () => {
    let arr = studentsData.filter((res) => res.status === true)
    if (arr.length > 0) {
      setShowTotalStudentsAddedModal(true)
    }
  }

  const handleGenerateList = () => {
    try {
      let studentList = [...bulkUploadData]
      studentList = studentList.map((data) => {
        if (data?.phone_number_with_cc?.length > 0 && data?.phone_number_with_cc?.charAt(0) !== "+") {
          data.phone_number_with_cc = "+" + data.phone_number_with_cc
        }
        return data
      })
      setStudentsData(studentList)
      setShowAddStudentsModal(true)
      setShowUploadStudentsModal(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <React.Fragment>
      <Modal show={showAddStudentsModal} centered size="lg" scrollable={true} className={`${StylesM.classroom_delete_modal} modal-content-bg-transparent`}>
        <Modal.Header className={`${StylesM.delete_modal_header} ${Styles.header_border}`}>
          <div className={`d-inline-flex w-100 align-items-center`}>
            <p>Add Student</p>
            <img
              onClick={(e) => {
                e.preventDefault()
                props.setShow(false)
                handleShowCount()
                setShowAddStudentsModal(false)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto mr-1 pointer ${Styles.modal_close_icon}`}
            />
          </div>
        </Modal.Header>
        <Modal.Body className={`align-items-center d-inline-flex w-100 p-3 ${Styles.modal_body_style}`}>
          {HasCreatePermission(STUDENT) ? (
            <div className={`d-inline-block w-100 align-items-center ${Styles.max_h_470}`} id="scrollable_div">
              {studentsData.length > 0 ? (
                studentsData.map((student, index) => {
                  return (
                    <div className={`d-flex`} key={`participant_${index}`}>
                      <div className={`w-100 mb-2 bg-303C54 inner-element-box-shadow br-9px`}>
                        <p className={`mb-0 px-2 py-2 fs-16px ${Styles.student_title}`}>{`Student ${index + 1}`}</p>
                        <div className={`d-inline-block w-100 align-items-center px-2 py-2`}>
                          <FormControl
                            placeholder="Name"
                            aria-label="Username"
                            className={` mb-2 border-0 pl-2 shadow-none ${Styles.input_field_style}`}
                            value={student.name}
                            onChange={(e) => {
                              setClickedSubmit(false)
                              let dummy = [...studentsData]
                              dummy[index].name = e.target.value
                              setStudentsData(dummy)
                            }}
                            readOnly={!HasCreatePermission(STUDENT)}
                          />
                          <FormControl
                            placeholder="Email"
                            aria-label="Usermail"
                            className={`mb-2 border-0 pl-2 shadow-none ${Styles.input_field_style}`}
                            value={student.email}
                            onChange={(e) => {
                              setClickedSubmit(false)
                              let dummy = [...studentsData]
                              dummy[index].valid = true
                              dummy[index].email = e.target.value
                              setStudentsData(dummy)
                            }}
                            readOnly={!HasCreatePermission(STUDENT)}
                          />
                          <FormControl
                            type="tel"
                            placeholder="Mobile Number with Country Code"
                            aria-label="Userphone"
                            className={`border-0 pl-2 shadow-none  ${Styles.input_field_style}`}
                            value={student.phone_number_with_cc}
                            onChange={(e) => {
                              setClickedSubmit(false)
                              let dummy = [...studentsData]
                              dummy[index].valid = true
                              dummy[index].phone_number_with_cc = e.target.value
                              setStudentsData(dummy)
                            }}
                            readOnly={!HasCreatePermission(STUDENT)}
                          />
                        </div>
                        {(fileStatus === "uploaded" || fileStatus === "") && student.valid ? (
                          <p className={`mb-0 pb-3 px-3 fs-14px ${Styles.yellow}`}>{student.status ? "Student already assigned to this class" : ""}</p>
                        ) : (
                          <p className={`mb-0 pb-3 px-3 fs-14px ${Styles.red}`}>Invalid Name/Email/Phone Number.</p>
                        )}
                      </div>
                      {(fileStatus !== "" || (fileStatus == "" && studentsData.length > 1)) && HasDeletePermission(STUDENT) && (
                        <Btn
                          style={{ backgroundColor: "#42506C" }}
                          type={`round`}
                          className={`ml-2`}
                          onClick={() => {
                            setDeleteModalData({ data: student, index: index })
                            // deleteParticipant(index)
                          }}
                        >
                          <Image style={{ width: pixelResize(18), height: pixelResize(18, "height") }} src={Delete} alt={`Delete`} className={`mb-0 mx-auto`} />
                        </Btn>
                      )}
                    </div>
                  )
                })
              ) : (
                <p>Add Student</p>
              )}
              <DeleteModal
                show={deleteModalData !== null}
                onHide={() => {
                  setDeleteModalData(null)
                }}
                itemName={deleteModalData?.data?.name}
                action={() => {
                  deleteParticipant(deleteModalData?.index)
                  setDeleteModalData(null)
                }}
              />
            </div>
          ) : (
            <NoPermissionError />
          )}
        </Modal.Body>
        <Modal.Footer className={`p-0 d-block py-1 ${Styles.modal_fotter_style}`}>
          {HasCreatePermission(STUDENT) && (
            <div className={`d-inline-flex align-items-center w-100 px-3`}>
              {fileStatus == "" && (
                <React.Fragment>
                  <Btn
                    type="small"
                    className={`px-3 py-1 mr-2`}
                    onClick={(e) => {
                      addStudent()
                    }}
                  >
                    Add student
                  </Btn>

                  <Btn
                    type="small"
                    className={`px-3 py-1`}
                    onClick={(e) => {
                      props.setShow(false)
                      handleShowCount()
                      setShowAddStudentsModal(false)
                      setShowUploadStudentsModal(true)
                    }}
                  >
                    Upload file/Bulk upload
                  </Btn>
                </React.Fragment>
              )}
              <Btn
                type="small"
                className={`px-3 py-1 ml-auto`}
                onClick={(e) => {
                  e.preventDefault()
                  allEmailValidation()
                }}
              >
                I am done!
              </Btn>
            </div>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUploadStudentsModal}
        onHide={() => {
          setShowUploadStudentsModal(false)
        }}
        centered
        size="md"
        scrollable={true}
        className={`${Styles.modal_body_style_upload}} modal-content-bg-transparent`}
      >
        <Modal.Body className={`align-items-center text-center d-inline-flex w-100 px-3 ${Styles.modal_body_style_upload}`}>
          <div className={`w-100 ${fileStatus !== "" ? "mb-0" : "mb-2"}  ${fileStatus !== "" ? "pb-0" : "pb-5"}`}>
            <div className={`px-2 py-1`}>
              <img
                onClick={(e) => {
                  e.preventDefault()
                  setShowUploadStudentsModal(false)
                  setFileStatus("")
                  setIsValidFile(true)
                  // setFileName(null)
                }}
                src={CancelWhite}
                alt={`Cancel`}
                className={`my-auto mr-1 pointer ${Styles.modal_close_icon}`}
              />
            </div>
            {fileStatus !== "" && (
              <div>
                <div className={`${Styles.document_div}`}>
                  <Image src={Document} alt={"File"} className={`mx-1 my-3`} />
                </div>
                {fileStatus !== "error" && <p className={`mt-1 mb-0 fs-12px text-white`}>{fileName}</p>}
                {fileStatus === "error" && (
                  <React.Fragment>
                    <div className="d-flex mb-2 mt-2 justify-content-center">
                      <Image className={`mb-0 ${Styles.icon}`} src={Error} alt={"error"} />
                      <p className={`ml-1 mb-0 ${Styles.red} fs-12px`}>{errorMessage}</p>
                    </div>
                    <label htmlFor="fileElemResource">
                      <Btn type="small" className={`px-4 py-1 mr-2`}>
                        Try again
                      </Btn>
                    </label>
                  </React.Fragment>
                )}
                {fileStatus === "uploading" && <p className={`mt-1 mb-0 text-grey fs-12px`}>Uploading file ..</p>}
                {fileStatus === "uploaded" && (
                  <React.Fragment>
                    <p className={`mt-1 mb-2 text-grey fs-12px`}>File uploaded successfully!</p>
                    <Btn onClick={handleGenerateList}>Generate Students List</Btn>
                    <p className={`mt-1 mb-0 fs-11px text-grey`}>Ensure you are generating the correct list</p>
                  </React.Fragment>
                )}
              </div>
            )}

            <p className={`mb-0 fs-16px text-white ${fileStatus !== "" ? "pt-3" : "pt-5"}`}>
              We support only <span className="font-weight-bold">.csv</span> files
            </p>
            <p className={`mb-2 text-white fs-12px`}>Please ensure the data in your file is in the below format</p>
            <div
              className={`d-flex ${fileStatus !== "" ? "mb-0" : "mb-3"} pointer ${Styles.file_link}`}
              onClick={() => {
                window.location.href = SAMPLE_FILE
              }}
            >
              <p className={`mb-0 text-white fs-11px`}>View sample format</p>
              <Image className={`mb-0 ml-1 ${Styles.icon}`} src={CSV} alt={`CSV`} />
            </div>
            <input
              className={`d-none`}
              type="file"
              id="fileElemResource"
              multiple
              accept=".csv"
              onChange={(e) => {
                e.preventDefault()
                validateAndAdd(e)
              }}
            />
            {fileStatus === "" && (
              <label htmlFor="fileElemResource">
                <Btn type="small" className={`px-4 py-1 mr-2`}>
                  Browse file to upload
                </Btn>
              </label>
            )}

            {isValidFile ? (
              ""
            ) : (
              <div className="d-flex justify-content-center">
                <Image className={`mb-0 ${Styles.icon}`} src={Error} alt={`error`} />
                <p className={`mb-0 ml-1 fs-12px ${Styles.red}`}>Please upload a valid file!</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showTotalStudentsAddedModal && studentsAddedCount > 0}
        onHide={() => {
          resetStates()
        }}
        centered
        size="lg"
        scrollable={true}
        className={`${StylesM.classroom_delete_modal} modal-content-bg-transparent`}
      >
        <Modal.Body className="align-items-center d-inline-flex w-100 px-4 bg-2">
          <div className={`d-inline-flex text-center px-5 w-100 align-items-center overflow-auto ${Styles.max_h_470}`}>
            <Col xs="12" sm="12" md="12" lg="12" className={`mb-4 w-100 my-3 pt-2 mb-3 text-center align-items-center`}>
              <Image src={SucessTick} alt="success" />
              <p className={`text-white font-weight-bold fs-20px`}>
                {studentsAddedCount} {studentsAddedCount > 1 ? `Students have` : `Student has`} been added successfully
              </p>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
      <Notification data={toastData} setData={setToastData} />
    </React.Fragment>
  )
}
export default UploadParticipantsModal
