import React, { useCallback, useContext, useState } from "react"
import _ from "lodash"

import Image from "../custom/Image"
import WarningIcon from "../../images/svgs/warning_icon.svg"
import muralIcon from "../../images/svgs/mural-icon.svg"
import edit from "../../images/svgs/edit.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import PropTypes from "prop-types"
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import Styles from "./style.module.scss"
import Btn from "../custom/Button"
import WarningModal from "../custom/WarningModal"
import { navigate } from "gatsby"
import { deleteRequest, putRequest } from "../../services/rest_service"
import refreshToken from "../mural/refreshToken"

const MuralList = (props) => {
  const { mural } = props

  const [warning, setWarning] = useState({})
  const [loader, setLoader] = useState(null)
  const [muralActivationFlag, setMuralActivationFlag] = useState(true)
  const [muralEditData, setMuralEditData] = useState({})
  const handleMuralToggle = async () => {
    setMuralActivationFlag(!muralActivationFlag)
    let validMural = await refreshToken(mural.id)
    if (validMural) {
      updateMuralData({ activated: muralActivationFlag }, mural.id)
    }
  }

  const closeWarningModalHandler = () => {
    //Close warning modal
    setWarning({})
  }
  const deactivateHandler = async () => {
    let res = await deleteRequest(`/murals/${mural?.id}/`)
    if (res.status) {
      if (res.success) {
        props.onMuralChanged({}, true)
      }
    }
  }

  const delayedSaveMuralAccount = useCallback(
    _.debounce((value, data, field) => {
      updateMuralData({ [field]: value }, data.id)
    }, 1500),
    []
  )

  const updateMuralData = async (payload, id) => {
    let res = await putRequest(`/murals/${id}/`, payload)
    if (res.success) {
      props.onMuralChanged(payload, false)
    }
  }

  const handleDelete = () => {
    setWarning({
      p1: "You are currently using your account in one or more courses",
      p2: "Removing it will restrict you to access the mural account for all future classes",
      p3: `Are you sure you want to remove ${mural.name}’s Account?`,
      okayBtnName: "Remove",
      cancelBtnName: "Don’t remove",
      cancel: closeWarningModalHandler,
      action: deactivateHandler,
    })
  }
  return (
    <>
      <WarningModal
        show={warning}
        onHide={closeWarningModalHandler}
        action={warning?.action}
        okayBtnName={warning?.okayBtnName}
        cancelBtnName={warning?.cancelBtnName}
        loader={loader === "warning"}
        icon={WarningIcon}
      >
        <div className={`d-flex flex-column`}>
          <div>{warning?.p1}</div>
          <div className={`mb-3`}>{warning?.p2}</div>
          <div className={`fs-14px`}>{warning?.p3}</div>
        </div>
      </WarningModal>

      <div className="d-flex flex-row align-items-center my-2 justify-content-between ">
        <div className="d-flex flex-row">
          <div className="position-relative w-2rem">
            <Image src={muralIcon} alt="mural" className={`my-auto w-1_5rem h-1_5rem`} />
            {!mural.activated && (
              <Image src={WarningIcon} alt="mural" className={`my-auto position-absolute top--35 right--25 h-1rem w-1rem object-fit-content `} />
            )}
          </div>
          {muralEditData?.id === mural.id ? (
            <>
              <input
                type="text"
                className={`se-list-layer ${Styles.mural_account_input} text-white ml-2 fs-16px`}
                defaultValue={muralEditData.name}
                onChange={(e) => {
                  delayedSaveMuralAccount(e.target.value, mural, "name")
                }}
              />
              <Btn
                className="fs-16px ml-7px py-0 pl-14px pr-14px"
                onClick={() => {
                  setMuralEditData({})
                }}
              >
                ok
              </Btn>
            </>
          ) : (
            <>
              {" "}
              <p className="d-inline-flex mb-0 pl-2 turient-header-text-color fs-16px bold-500 mr-2">{mural.name}</p>
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip className="event-tooltip fs-12px w-23rem" id={`tooltip-${mural.name}`}>
                    Rename{" "}
                  </Tooltip>
                }
              >
                <Image
                  src={edit}
                  alt="Edit Mural"
                  className="pointer w-13px "
                  onClick={() => {
                    setMuralEditData({ id: mural.id, name: mural.name })
                  }}
                />
              </OverlayTrigger>
            </>
          )}
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          {!mural.activated && (
            <OverlayTrigger
              placement={"bottom"}
              overlay={
                <Tooltip className="event-tooltip fs-12px w-23rem" id={`tooltip-${mural.name}`}>
                  We have noticed some changed in your mural account. Please re-authenticate and start using{" "}
                </Tooltip>
              }
            >
              <Button
                className={`border_on_hover position-relative ${Styles.btn} text-center px-3 py-1 bg-AA5746 fs-14px turient-header-text-color br-20px `}
                onClick={() => {
                  navigate(`/app/mural?redirect_url=${window.location.href}&muralAuthId=${mural.id}`)
                }}
              >
                Re-authenticate
              </Button>
            </OverlayTrigger>
          )}
          <Form.Check
            type={"switch"}
            name="mural_enabled"
            checked={mural.activated}
            onChange={handleMuralToggle}
            id={`${mural.id}-index`}
            inputprops={{ className: Styles.muralToggle }}
            className="ml-2 pointer"
          />
          <Btn
            className={`bg_light_navy`}
            type={`round`}
            onClick={() => {
              handleDelete()
            }}
          >
            <Image src={DeleteIcon} alt="Delete" className=" mb-0 mx-auto h-13px" />
          </Btn>
        </div>
      </div>
    </>
  )
}

MuralList.propTypes = {
  mural: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    activated: PropTypes.bool.isRequired,
  }).isRequired,
  onMuralChanged: PropTypes.func.isRequired,
}

export default MuralList
