import React from "react"
import PropTypes from "prop-types"

// import { ResponsiveLine } from "@nivo/line"

import DashedSolidLine from "../DashedSolidLine"

const CommonLineGraph = ({ graphData, legends, ...rest }) => {
  return (
    <div style={{ height: "100%" }}>
      {/* <ResponsiveLine
        data={graphData}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        layers={["grid", "markers", "axes", "areas", "crosshair", "slices", "points", "mesh", "legends", DashedSolidLine]}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: "#777777",
                strokeWidth: 1,
              },
            },
          },
          grid: {
            line: {
              stroke: "#777777",
              strokeWidth: 1,
            },
          },
          background: "#101010",
          textColor: "#8a94a8",
          fontSize: 12,
        }}
        pointSize={8}
        pointBorderWidth={0}
        pointBorderColor={"white"}
        useMesh={true}
        gridXValues={0}
        gridYValues={1}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 123,
            translateY: 60,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 8,
            symbolSize: 20,
            symbolShape: "circle",
            itemDirection: "left-to-right",
            itemTextColor: "#777",
            data: legends,
            effects: [],
          },
        ]}
        {...rest}
      /> */}
    </div>
  )
}

CommonLineGraph.propTypes = {
  graphData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      colorCode: PropTypes.string.isRequired,
      isDotted: PropTypes.bool.isRequired,
      data: PropTypes.array.isRequired,
    })
  ).isRequired,
  legends: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired, label: PropTypes.string.isRequired, color: PropTypes.string.isRequired }))
    .isRequired,
  enableGridX: PropTypes.bool,
  enableGridY: PropTypes.bool,
  xScale: PropTypes.object,
  yScale: PropTypes.object,
  axisLeft: PropTypes.object,
  axisBottom: PropTypes.object,
  tooltip: PropTypes.func,
  xFormat: PropTypes.string,
  yFormat: PropTypes.string,
}

export default CommonLineGraph
