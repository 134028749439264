import React, { useContext, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import PropTypes from "prop-types"
import PlusLogo from "../../images/svgs/plus.svg"
import Style from "./style.module.scss"
import CancelWhite from "../../images/svgs/close.svg"
import WarningModal from "../custom/WarningModal"
import MuralApp from "./mural"
import Loader from "../custom/Loader"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import Notification from "../custom/Notification"
import { SET_APP_DATA } from "../../stores/global_store/GlobalActions"
import MuralList from "./MuralList"
import Image from "../custom/Image"
import { navigate } from "gatsby"
import WarningIcon from "../../images/svgs/warning_icon.svg"

// !definition of component
/**
 * @returns App Integration Modal
 */
// ! component

const AppIntegrationModal = () => {
  const [globalStore, dispatchGlobalStore] = useContext(GlobalContext)
  const [warning, setWarning] = useState({})
  const [loader, setLoader] = useState(null)
  const [notificationData, setNotificationData] = useState(null)

  const closeWarningModalHandler = () => {
    //Close warning modal
    setWarning({})
  }

  const handleHideModal = () => {
    dispatchGlobalStore({ type: SET_APP_DATA, payload: { showModal: false } })
  }

  return (
    <>
      <Modal show={globalStore.appData.showModal} onHide={handleHideModal} centered className={`${Style.modal}`} contentClassName={`${Style.content}`}>
        <div className={`${Style.header}`}>
          <div>Manage Mural</div>
          <div>
            <img onClick={handleHideModal} src={CancelWhite} alt={`Cancel`} className={`${Style.close_icon}`} />
          </div>
        </div>
        <Modal.Body className={Style.body}>
          {globalStore.appData.loader ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="w-100">
              <p className="fs-14px text-left">Available Accounts</p>
              <div className={`col-12 p-0 mb-5`}>
                {globalStore.appData?.data?.mural?.map((item, index) => {
                  return (
                    <MuralList
                      key={index}
                      mural={item}
                      onMuralChanged={(changedData, isDelete = false) => {
                        let muraldata = globalStore.appData.data.mural
                        if (isDelete) {
                          muraldata.splice(index, 1)
                        } else {
                          muraldata[index] = { ...muraldata[index], ...changedData }
                        }
                        dispatchGlobalStore({
                          type: SET_APP_DATA,
                          payload: {
                            data: {
                              ...globalStore.appData.data,
                              mural: muraldata,
                            },
                          },
                        })
                      }}
                    />
                  )
                })}
              </div>
              <Button
                className={`d-flex bg-586886 flex-row align-items-center justify-content-center br-9px fs-12px h-2rem border_on_hover w-100`}
                onClick={() => {
                  navigate(`/app/mural?redirect_url=${window.location.href}`)
                }}
              >
                <Image src={PlusLogo} alt="Add Icon" className="h-12px mr-2 " />
                Add Account
              </Button>
            </div>
          )}
          <Notification data={notificationData} className={Style.notification} setData={setNotificationData} />
        </Modal.Body>
      </Modal>
      <WarningModal
        show={warning}
        onHide={closeWarningModalHandler}
        action={warning?.action}
        okayBtnName={warning?.okayBtnName}
        cancelBtnName={warning?.cancelBtnName}
        loader={loader === "warning"}
        icon={WarningIcon}
      >
        <div className={`d-flex flex-column`}>
          <div>{warning?.p1}</div>
          <div className={`mb-3`}>{warning?.p2}</div>
          <div className={`${Style.font14px}`}>{warning?.p3}</div>
        </div>
      </WarningModal>
    </>
  )
}

export default AppIntegrationModal
