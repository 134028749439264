import React, { useState } from "react"
import PropTypes from "prop-types"
import { copyToClipboard } from "../../../services/common"
import { LazyLoadImage } from "react-lazy-load-image-component"
import CopyIcon from "/src/images/svgs/copy.svg"
import CheckWhite from "/src/images/svgs/check-white.svg"

const CopyToClipBoardWithIcon = ({ textToBeCopied, resetTime }) => {
  const [isCopied, setIsCopied] = useState(false)

  const afterCopy = () => {
    setIsCopied(true)
    if (resetTime) {
      setTimeout(() => {
        setIsCopied(false)
      }, resetTime)
    }
  }

  const onClickCopy = (e) => {
    e.stopPropagation()
    copyToClipboard(e, textToBeCopied, afterCopy)
  }

  return <LazyLoadImage className="pointer" onClick={onClickCopy} src={isCopied ? CheckWhite : CopyIcon} />
}

export default CopyToClipBoardWithIcon

CopyToClipBoardWithIcon.propTypes = {
  textToBeCopied: PropTypes.string.isRequired,
  resetTime: PropTypes.number,
}
