import React from "react"
import PropTypes from "prop-types"

const CustomGraphToolTip = ({ point, showX, showY, xLabel, yLabel, leftBorderColor, additionalElements }) => {
  return (
    <div className={"graph_tooltip_bg graph_tooltip_xy_text"} style={{ borderLeft: `5px solid ${leftBorderColor}` }}>
      {showX && (
        <div>
          {xLabel}: {point?.data?.xFormatted}
        </div>
      )}
      {showY && (
        <div>
          {yLabel}: {point?.data?.yFormatted}
        </div>
      )}
      {additionalElements}
    </div>
  )
}

CustomGraphToolTip.defaultProps = {
  showX: true,
  showY: true,
  xLabel: "X",
  yLabel: "Y",
  leftBorderColor: "#D07530",
}

CustomGraphToolTip.propTypes = {
  point: PropTypes.object.isRequired,
  showX: PropTypes.bool,
  showY: PropTypes.bool,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  leftBorderColor: PropTypes.string,
  additionalElements: PropTypes.array,
}

export default CustomGraphToolTip
