import React, { createContext, useReducer } from "react"

import InsightReducer from "./InsightReducer"

const initalState = {
  type: null,
  data: {},
  activeChart: "Session Burndown Chart",
  activeModuleData: null,
}

const InsightStore = ({ children }) => {
  const [state, dispatch] = useReducer(InsightReducer, initalState)

  return <InsightContext.Provider value={[state, dispatch]}>{children}</InsightContext.Provider>
}

export const InsightContext = createContext()
export default InsightStore
