import React from "react"
import PropTypes from "prop-types"
import Modal from "react-bootstrap/Modal"
import Style from "./style.module.scss"
import Btn from "../Button"

// !definition of component
/**
 *
 * @param props --> show , onHide, action, okayBtnName, cancelBtnName and children function
 * @description -->  show=> boolean value to show or hide the warning modal, onHide=> function to be
 *  executed onHide of warning modal, action=> function on okay button, okayBtnName/cancelBtnName string to chnage the name of respective button
 * @returns Warning Modal
 */
// ! component

const WarningModal = (props) => {
  return (
    <Modal show={Object.keys(props.show).length > 0} onHide={props.onHide} centered className={`${Style.modal}`} contentClassName={`${Style.content}`}>
      <div className={`${Style.header}`}>
        {props.icon && (
          <div>
            <img src={props?.icon} alt={`warning icon`} className={`my-auto`} style={{ objectFit: `contain`, height: `40px` }} />
          </div>
        )}
      </div>
      <Modal.Body className={Style.body}>
        <div className={`${Style.children}`}>{props.children}</div>
        <div className="d-flex justify-content-center mt-3">
          <Btn disabled={props?.loader} type="small" style={{ backgroundColor: "#303C54" }} className={`px-4 mr-3`} onClick={props?.action}>
            {props.okayBtnName}
          </Btn>
          <Btn disabled={props?.loader} type="small" style={{ backgroundColor: "#303C54" }} className={`px-4`} onClick={props?.onHide}>
            {props.cancelBtnName}
          </Btn>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default WarningModal

WarningModal.propTypes = {
  onHide: PropTypes.func,
  action: PropTypes.func,
  okayBtnName: PropTypes.string.isRequired,
  cancelBtnName: PropTypes.string.isRequired,
}
