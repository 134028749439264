import { isBrowser } from "../../services/developer_mode"

const convertPercentToPixelBasedOnScreenSize = (percent, type) => {
  let screen_size, pixel
  if (window.screen) {
    if (type === "width_or_font") {
      screen_size = window.screen.width
    } else {
      screen_size = window.screen.height
    }
    pixel = Math.floor(screen_size * percent)
    return pixel
  }
}

const convertWidthAndFontPixel = (pixel) => {
  let width_and_font_pixel = convertPercentToPixelBasedOnScreenSize(pixel / 1920, "width_or_font")
  return `${width_and_font_pixel}px`
}

const convertHeightPixel = (pixel) => {
  let height_pixel = convertPercentToPixelBasedOnScreenSize(pixel / 1080)
  return `${height_pixel}px`
}

const pixelResize = (pixel, type) => {
  if (isBrowser()) {
    if (type === "height") {
      return convertHeightPixel(pixel)
    } else {
      return convertWidthAndFontPixel(pixel)
    }
  } else {
    return pixel
  }
}

export default pixelResize
