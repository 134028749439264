import { RESET_INSIGHT_DATA, SET_INSIGHT_DATA, SET_ACTIVE_MODULE_DATA } from "./InsightActions"

const InsightReducer = (state, action) => {
  switch (action.type) {
    case SET_INSIGHT_DATA:
      return {
        ...state,
        data: action.payload.data,
        type: action.payload.type,
        activeChart: action.payload.activeChart,
      }
    case RESET_INSIGHT_DATA:
      return {
        ...state,
        data: {},
        type: null,
        activeChart: "session-burndown",
        activeModuleData: null,
      }
    case SET_ACTIVE_MODULE_DATA:
      return {
        ...state,
        activeModuleData: { ...action.payload },
      }
    default:
      return state
  }
}

export default InsightReducer
