import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"

import Style from "./style.module.scss"
import muralIcon from "../../images/svgs/mural-icon.svg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Btn from "../custom/Button"
import refreshToken from "../mural/refreshToken"
import successTick from "../../images/svgs/sucesstick.svg"
import { postRequest } from "../../services/rest_service"
import utcDateTime from "../../services/utcdatetime"
import { clearMuralData, setMuralData } from "../mural/config"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_APP_DATA } from "../../stores/global_store/GlobalActions"

// !definition of component
/**
 *
 * @param props -->  setLoader, setWarning, setNotificationData
 * @description -->  setLoader=> set loader for button, setWarning => set warning for warning modal, setNotificationData=> set notification text and type
 * @returns Mural App SignIn
 */
// ! component

const MuralApp = (props) => {
  const [globalStore, dispatchGlobalStore] = useContext(GlobalContext)
  const [status, setStatus] = useState("activate")
  const [loader, setLoader] = useState(null)

  useEffect(() => {
    if (globalStore.appData) {
      if (globalStore.appData.data?.mural) {
        if (globalStore.appData.data?.mural?.activated) {
          setStatus("deactivate")
        } else {
          setStatus("reactivate")
        }
      } else {
        setStatus("activate")
      }
    }
  }, [globalStore.appData])

  const formatDate = (date) => {
    let formatedDate = utcDateTime.utcDateTime(date, "date_with_prefix") + " "
    formatedDate += utcDateTime.utcDateTime(date, "short_month_name") + " "
    formatedDate += utcDateTime.utcDateTime(date, "year")
    return formatedDate
  }

  const activateClickHandler = () => {
    //Activate/SignIn code
    refreshToken()
  }

  const closeWarningModalHandler = () => {
    //Close warning modal
    props.setWarning({})
  }

  const deactivateClickHandler = () => {
    //Pop warning modal
    props.setWarning({
      p1: "You are currently using your workspace in one or more courses.",
      p2: "Deactivating will restrict students to access the mural workspace.",
      p3: "Are you sure you want to deactivate Mural?",
      okayBtnName: "Yes, deactivate",
      cancelBtnName: "No, don't deactivate",
      cancel: closeWarningModalHandler,
      action: deactivateHandler,
    })
  }

  const reactivateClickHandler = async () => {
    //Reactivate mural code
    try {
      setLoader("activate")
      let company_id = localStorage.getItem("company_id")
      let formData = createFormdata("activate")
      let response = await postRequest(`/company/integrations/${company_id}/app_control/`, formData)
      if (response.success) {
        // props.details.setData(response.data)
        dispatchGlobalStore({ type: SET_APP_DATA, payload: { data: response.data } })
        let data = response.data.mural.auth_creds
        setMuralData(data.access_token, data.expires_at)
        props.setNotificationData({ type: "success", title: "Mural Activated Successfully" })
        setStatus("deactivate")
      } else {
        props.setNotificationData({ type: "error", title: response.msg })
        deleteHandler()
      }
      props.setLoader(null)
      setLoader(null)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteClickHandler = () => {
    //Pop warning modal
    props.setWarning({
      p1: "You are currently using Mural in one or more courses.",
      p2: "Deleting will remove mural and the data associated with it.",
      p3: "Are you sure you want to delete Mural?",
      okayBtnName: "Delete",
      cancelBtnName: "No, don't Delete",
      cancel: closeWarningModalHandler,
      action: deleteHandler,
    })
  }

  const deactivateHandler = async () => {
    //Deactivate mural code
    try {
      props.setLoader("warning")
      let company_id = localStorage.getItem("company_id")
      let formData = createFormdata("deactivate")
      let response = await postRequest(`/company/integrations/${company_id}/app_control/`, formData)
      if (response.success) {
        // props.details.setData(response.data)
        dispatchGlobalStore({ type: SET_APP_DATA, payload: { data: response.data } })
        setStatus("reactivate")
        props.setNotificationData({ type: "info", title: "Mural Deactivated Successfully." })
        clearMuralData()
        closeWarningModalHandler()
      } else {
        props.setNotificationData({ type: "error", title: "Something went wrong." })
      }
      props.setLoader(null)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteHandler = async () => {
    //Delete mural
    try {
      props.setLoader("warning")
      let company_id = localStorage.getItem("company_id")
      let formData = createFormdata("delete")
      let response = await postRequest(`/company/integrations/${company_id}/app_control/`, formData)
      if (response.success) {
        // props.details.setData(response.data)
        dispatchGlobalStore({ type: SET_APP_DATA, payload: { data: response.data } })
        setStatus("activate")
        clearMuralData()
        props.setNotificationData({ type: "info", title: "Mural Deleted Successfully" })
        props.setLoader(null)
        closeWarningModalHandler()
      } else {
        props.setNotificationData({ type: "error", title: response.msg })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const actions = {
    activate: { buttonName: "Activate", action: activateClickHandler },
    deactivate: { buttonName: "Deactivate", action: deactivateClickHandler },
    reactivate: { buttonName: "Reactivate", action: reactivateClickHandler },
  }

  const createFormdata = (action) => {
    let formData = new FormData()
    formData.append("app", "mural")
    formData.append("action", action)
    return formData
  }

  return (
    <div className={`${Style.appBox} mx-1`}>
      <div className={`position-relative`} style={{ width: "fit-content" }}>
        <LazyLoadImage src={muralIcon} style={{ height: "50px" }} className={`mb-0`} />
        {status === "deactivate" && <LazyLoadImage src={successTick} className={`${Style.tick}`} />}
      </div>
      <div>Mural</div>
      {status === "activate" && <div>Inactive</div>}
      {status === "deactivate" && <div>Activated on {formatDate(globalStore.appData?.data?.mural?.updated_at)}</div>}
      {status === "reactivate" && <div>Deactivated on {formatDate(globalStore.appData?.data?.mural?.updated_at)}</div>}
      <div>
        <Btn
          disabled={loader === "activate"}
          onClick={() => {
            actions[status].action()
          }}
          className={`mr-2`}
        >
          {actions[status].buttonName}
        </Btn>
        {status !== "activate" && <Btn onClick={deleteClickHandler}>Delete</Btn>}
      </div>
    </div>
  )
}

MuralApp.propTypes = {
  setLoader: PropTypes.func.isRequired,
  setWarning: PropTypes.func.isRequired,
  setNotificationData: PropTypes.func.isRequired,
}

export default MuralApp
