import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"

import Loader from "../../custom/Loader"
import { postRequest } from "../../../services/rest_service"
import CommonLineGraph from "./commonLineGraph"

const InsightLineGraph = ({ fetchUrl, payload, ...rest }) => {
  const [graphData, setGraphData] = useState([])
  const [graphLegends, setGraphLegends] = useState([])
  const [insightDataFetched, setInsightDataFetched] = useState(false)
  const [apiProps, setApiProps] = useState({})

  useEffect(() => {
    fetchInsightGraphData()
  }, [])

  const fetchInsightGraphData = async () => {
    let res = await postRequest(fetchUrl, payload)
    if (res.success) {
      setGraphData(res.data?.graphs)
      setGraphLegends(res.data?.legends)
      if (res.data?.xMaxValue) {
        setApiProps((prevValue) => ({ ...prevValue, xScale: { ...rest?.xScale, max: res.data?.xMaxValue } }))
      }
      if (res.data?.yMaxValue) {
        setApiProps((prevValue) => ({ ...prevValue, yScale: { ...rest?.yScale, max: res.data?.yMaxValue } }))
      }
    }
    setInsightDataFetched(true)
  }

  var graphSectionContent = null
  if (insightDataFetched) {
    if (graphData?.length > 0) {
      graphSectionContent = <CommonLineGraph graphData={graphData} legends={graphLegends} {...rest} {...apiProps} />
    } else {
      graphSectionContent = (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <p className="text-white mx-auto">Data not available</p>
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      {!insightDataFetched && (
        <div className="w-100 d-flex h-100 justify-center align-items-center">
          <Loader class={`text-white mx-auto`} />
        </div>
      )}
      {graphSectionContent}
    </React.Fragment>
  )
}

InsightLineGraph.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  payload: PropTypes.object.isRequired,
  enableGridX: PropTypes.bool,
  enableGridY: PropTypes.bool,
  xScale: PropTypes.object,
  yScale: PropTypes.object,
  axisLeft: PropTypes.object,
  axisBottom: PropTypes.object,
  tooltip: PropTypes.func,
  xFormat: PropTypes.string,
  yFormat: PropTypes.string,
}

export default InsightLineGraph
