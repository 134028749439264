import React from "react"
import PropTypes from "prop-types"

const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, colorCode, isDotted }, index) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      stroke={colorCode}
      style={
        isDotted
          ? {
              strokeDasharray: "3, 6",
              strokeWidth: 1,
            }
          : {
              strokeWidth: 1,
            }
      }
    />
  ))
}

DashedSolidLine.propTypes = {
  seriesData: PropTypes.array.isRequired,
  lineGenerator: PropTypes.func.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
}

export default DashedSolidLine
